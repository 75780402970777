<template>
  <data-view :columnsFormat="columnsFormat" :askData="op_name" @refreshDo="chartRefresh"></data-view>
</template>
<script>
import dataView from '@/components/Common/DataView.vue'

export default {
  name: "suggestionList",
  props: [],
  components: {
    dataView
  },
  data() {
    return {
      op_name: this.$options.name,
      updateCount:0,
      columnsFormat: [
        /*{
          title: "所属城市",
          key: "city",
          align: "center",
          width: 90
        },*/
        {
          title: "线路名称",
          key: "line_name",
          align: "center",
          width: 90,
          render: (h, p) => {
            let url = "/baseInfo/line_c/" + p.row.line_code;
            return h(
              "a",
              {
                attrs: {
                  href: url,
                  target: "_blank"
                }
              },
              p.row.line_name
            );
          }
        },
        {
          title: "安评时间",
          render: (h, p) => {
            return h(
              "span",
              {
                attrs: {}
              },
              p.row.evaluate_year
            );
          },
          sortable: true,
          width: 120,
          align: "center",
          filters: [
            {
              label: ">2017",
              value: 1
            },
            {
              label: "≤2017",
              value: 2
            }
          ],
          filterMultiple: false,
          filterMethod(value, row) {
            if (value === 1) {
              return row.score > 2017;
            } else if (value === 2) {
              return row.score < 2017;
            }
          }
        },
        {
          title: "专业组",
          key: "major_system",
          align: "center",
          width: 150
        },
        {
          title: "建议内容",
          key: "content",
          align: "center"
        },
      ]
    };
  },
  methods: {
    chartRefresh(){
        this.updateCount++;
    },
  }
};
</script>
<style scoped>
</style>